import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    title: {
        paddingTop: 10,
        fontWeight: 500,
        letterSpacing: -1,
    },
    title_white: {
        paddingTop: 0,
        fontWeight: 500,
        letterSpacing: -1,
        color: "white",

    },
}));



class SectionTitle extends PureComponent {
    render() {
        const { title, classes, white} = this.props;
        if (title) {
            return (
                <Typography
                    variant='h5'
                    component='h3'
                    className={white ? classes.title_white : classes.title}
                >
                    {title}
                </Typography>
            );
        } else {
            return null;
        }
    }
}

SectionTitle.propTypes = {
    title: PropTypes.string,
    white: PropTypes.bool
};

export default function _SectionTitle(props) {
    const classes = useStyles();
    return <SectionTitle {...props} classes={classes} />;
}

